
import ProductDetail from "../features/product/components/ProductDetail";
import Footer from "../features/common/Footer";

function ProductDetailPage() {
    return ( 
        <div>
           
                <ProductDetail></ProductDetail>
           
            <Footer></Footer>
        </div>
     );
}

export default ProductDetailPage;