
import UserOrders from '../features/user/components/UserOrders';

function UserOrdersPage() {
  return (
    <div>
      
        <h1 className='mx-auto text-3xl text-center font-bold my-6 '>My Orders</h1>
        <UserOrders></UserOrders>
     
    </div>
  );
}

export default UserOrdersPage;
