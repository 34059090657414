

import UserProfile from '../features/user/components/UserProfile';

function UserProfilePage() {
  return (
    <div>
    
    <h1 className='mx-auto text-3xl text-center my-8 font-bold'>My Profile</h1>
        <UserProfile></UserProfile>

  
      
        
     
    </div>
  );
}

export default UserProfilePage;