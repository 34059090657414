import AdminProductDetail from "../features/admin/components/AdminProductDetail";
// import NavBar from "../features/navbar/Navbar";
function AdminProductDetailPage() {
    return ( 
        <div>
           
                <AdminProductDetail></AdminProductDetail>
           
        </div>
     );
}

export default AdminProductDetailPage;