import ProductForm from "../features/admin/components/ProductForm";
// import NavBar from "../features/navbar/Navbar";
function AdminProductFormPage() {
    return ( 
        <div>
           
                <ProductForm></ProductForm>
           
        </div>
     );
}

export default AdminProductFormPage;