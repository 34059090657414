import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper components
import { Navigation } from 'swiper/modules'; // Import Navigation module
import 'swiper/css/bundle'; // Import Swiper styles
import './styles.css'; // Import the CSS file for custom animations


const features = [
  {
    title: "Expert Instructors",
    description: "Our expert instructors, with extensive industry experience, deliver clear, concise explanations of complex topics using interactive methods to foster engagement. Committed to staying current with industry trends, they provide personalized guidance and constructive feedback, creating a supportive learning environment for continuous improvement and career advancement."
  },
  {
    title: "Flexible Learning",
    description: "We offer flexible learning options, from self-paced study to structured environments, catering to various preferences and schedules. Our approach includes online courses for anytime, anywhere access, blended learning, and intensive workshops, ensuring hands-on, real-world experience for learners to thrive in a competitive global landscape."
  },
  {
    title: "Comprehensive Curriculum",
    description: "Our comprehensive curriculum, crafted by experts in collaboration with industry professionals, balances theory and practical application, emphasizing hands-on learning, case studies, and real-world projects. Continuously updated to reflect current trends and best practices, it equips learners with valuable insights and practical knowledge for immediate career application, ensuring success in today’s competitive landscape."
  },
  {
    title: "Career Support",
    description: "We believe that learning extends beyond course completion to achieving career success. Our comprehensive career support includes resume building, interview preparation, networking tips, and job placement assistance. We help learners identify strengths, set career goals, and develop personalized action plans. By connecting them with a network of employers and providing ongoing support, we empower individuals to navigate the job market confidently and thrive in their careers."
  }
];

const testimonials = [
  { name: "Vaishali Sharma", text: "The content on CareerCraft is up-to-date and presented in a clear, engaging manner. The support team is incredibly helpful. This platform is a fantastic tool for anyone looking to advance their skills.", image: "/vaishali.jpg" },
  { name: "Ragini Malhotra", text: "The courses at CareerCraft are thorough and well-designed. I appreciate the real-world examples and hands-on projects that enhance learning. It’s a fantastic investment for anyone looking to improve their skills.", image: "/ragini.jpg" },
  { name: "Kamal Mehra", text: "CareerCraft offers top-notch courses with practical exercises that are directly applicable to my job. It’s helped me build new skills efficiently and effectively. Excellent resource for career advancement!", image: "/kamal.jpg" },
  { name: "Himanshi Singh", text: "The range of topics covered by CareerCraft is impressive, from technical skills to soft skills. The interactive elements keep me engaged, and the feedback is constructive.", image: "/himanshi.jpg" },
  { name: "Istekhar Alam", text: "My expectations were exceeded by CareerCraft. Learning is enjoyable since the courses are well-structured and include real-world applications. Additionally, the support staff responds quickly. A must-try for individuals who are committed to improving their skills!", image: "/istekhar.jpg" },
  { name: "Ajay Pawaria", text: "The variety of courses and hands-on approach at CareerCraft set it apart. It’s intuitive, effective, and helps me stay competitive in my field. The investment in my career has been worthwhile.", image: "/ajay.jpg" },
  { name: "Saurav Kumar", text: "After a month of use, CareerCraft has really changed my life. Their hands-on activities and interactive sessions make upskilling a breeze. Strongly advised for anyone trying to grow in their field!", image: "/sourav.jpg" }
];

const Main = () => {
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="main-content">
      {/* 3D Cube and Particles */}
      <div className="cube-background">
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
      </div>

      <div className="content">
        {/* Navbar */}
        <nav className="bg-gradient-to-r from-gray-800 via-gray-900 to-stone-800 text-white p-4 mx-auto max-w-8xl sm:px-6 lg:px-8">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <img src='/asu.png' className='h-8 w-12 border-2 border-gray-800' alt="Logo" />
              <Link to="/" className="text-3xl font-semibold font-roboto">Career Craft</Link>
            </div>
            <div className="hidden md:flex space-x-4 text-[18px] font-semibold">
              <Link to="/about" className="hover:underline font-roboto">About</Link>
              <Link to="/contact" className="hover:underline font-roboto">Contact Us</Link>
              <Link to="/login" className="hover:underline font-roboto">Login</Link>
            </div>
            <div className="md:hidden">
              <button onClick={toggleMenu} className="focus:outline-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
            </div>
          </div>
          {isMenuOpen && (
            <div className="md:hidden mt-2 space-y-2">
              <Link to="/about" className="block text-center hover:underline">About</Link>
              <Link to="/contact" className="block text-center hover:underline">Contact Us</Link>
              <Link to="/login" className="block text-center hover:underline">Login</Link>
            </div>
          )}
        </nav>

        {/* Header */}
        <header className="bg-white text-black">
          <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center justify-between">
            <div className="text-center lg:text-left mb-6 lg:mb-0">
              <h1 className="text-5xl font-bold font-roboto mb-4">Welcome to Career Craft</h1>
              <p className="text-2xl mb-6 font-roboto font-semibold">We're revolutionizing education through skill development to lead the New India.</p>
              <Link to="/login">
              <button className='btn-glow-bounce bg-blue-700 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-800
               transition duration-300 animate-bounce motion-safe:animate-bounce'>Check Our Products <span className='font-semibold'>&#x27F6;</span></button>



              </Link>
            </div>
            <div className="lg:w-1/2 w-full lg:pr-10 lg:py-10 px-14  rounded-lg ">
              <img 
                src="/carrercraftlogo.png" 
                alt="Career" 
                className="lg:w-96 w-auto lg:h-48 object-cover rounded border-0 mx-auto"  
                style={{ border: 'none' }} 
              />
            </div>
          </div>
        </header>

        {/* Features */}
        <section className="text-center p-10 bg-gray-800 py-20 text-white">
          <h2 className="text-5xl font-bold mb-6 font-roboto">Why Choose Career Craft?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {features.map((feature, index) => (
              <div key={index} className="bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800 p-6 rounded-lg shadow-md transform transition duration-300 hover:scale-105">
                <h3 className="text-2xl font-bold mb-4 text-blue-300">{feature.title}</h3>
                <p className="text-lg md:text-base leading-relaxed text-left md:text-justify text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

       

        
       {/* Testimonials */}
<section className="text-center p-10 bg-gray-700 py-20 text-white">
  <h2 className="text-4xl font-bold mb-6 font-roboto">What Our Students Say</h2>
  <Swiper
    modules={[Navigation]}
    navigation
    spaceBetween={20} // Adjust space between slides
    slidesPerView={1} // Default to 1 slide for smaller screens
    breakpoints={{
      640: {
        slidesPerView: 1, // 1 slide for small screens
      },
      768: {
        slidesPerView: 2, // 2 slides for medium screens
        spaceBetween: 30, // Space between slides
      },
      1024: {
        slidesPerView: 3, // 3 slides for larger screens
        spaceBetween: 30, // Space between slides
      },
    }}
  >
    {testimonials.map((testimonial, index) => (
      <SwiperSlide key={index} className="bg-white p-6 rounded-lg shadow-md">
        <img src={testimonial.image} alt={testimonial.name} className="h-52 w-40 mx-auto mb-4 object-cover" />
        <p className="text-lg italic text-gray-700 mb-4">"{testimonial.text}"</p>
        <p className="text-lg font-semibold text-gray-900">{testimonial.name}</p>
      </SwiperSlide>
    ))}
  </Swiper>
</section>

        {/* Login Section */}
        <section className="bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800 text-white text-center p-10">
          <h2 className="text-3xl font-bold mb-4">Ready to Upskill Yourself?</h2>
          <p className="text-xl mb-6">Join Career Craft today and take the first step towards your future.</p>
          <Link to="/login" className="bg-blue-700 text-white px-6 py-3 rounded-lg hover:bg-blue-800 transition duration-300">Sign Up Now</Link>
        </section>

        {/* Footer */}
        <footer className="bg-gray-900 text-white py-6">
          <div className="container mx-auto text-center">
            <p>&copy; 2024 Career Craft. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Main;